import { useEffect } from "react";

import "./css/App.css";
//@ts-ignore
import { DMInit, MultiUpload } from "digimaker-ui";

import "react-calendar/dist/Calendar.css";
import "@fontsource/saira/400.css";

import { MultiUpload as MultiUploadAction } from "digimaker-ui/actions";
import { FileMultiUpload } from "digimaker-ui/actions";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./DMEditorInit";
import queryString from "query-string";
import { MainContent } from "./pages/content/MainContent";
import { Login } from "./pages/Login";
import { getViewSettings, setRoles } from "./Config";
import { Create, Edit } from "digimaker-ui/actions";
import { Create as FullCreate } from "./pages/content/dmeditor/Create";
import { Edit as FullEdit } from "./pages/content/dmeditor/Edit";

import { DMRouteRender } from "./components/DMRouteRender";
import { KlubbLayout } from "./pages/club/Layout";
import { AdministrationLayout } from "./pages/administration/Layout";
import { Toaster } from "react-hot-toast";
import { useState } from "react";
import { Outlet } from "react-router";

import useSiteStore, { getSavedSiteID } from "./store/useSiteStore";
import { LayoutWebsite } from "./pages/content/layout/LayoutWebsite";
import { Layout } from "./pages/content/layout/Layout";
import CreateAnnualReport from "./components/annual_report/Create";
import EditAnnualReport from "./components/annual_report/Edit";
import { ClubMain } from "./pages/club/Main";
import { AdministrationMain } from "./pages/administration/Main";
import { Setup } from "./pages/administration/Setup";
import { Footer } from "./pages/content/Footer";
import { FetchWithAuth } from "digimaker-ui/util";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { ErrorHandler } from "./ErrorHandler";
import { initSite } from "./components/initSite";
import { View } from "./pages/content/ViewContent";
import { Users } from "./pages/administration/Users";
import { ForwardEmail } from "./pages/administration/ForwardEmail";
import {
  dmeConfig,
  DMEData,
  setDMEditorCallback,
  setDMEditorConfig,
} from "dmeditor";
import { KretsMain } from "./pages/krets/Main";
import { SiteSettings } from "./pages/content/siteSettings/SiteSetting";
import { ContentLibrary } from "./pages/content/ContentLibrary";
import { Pages } from "./pages/content/Pages";
import { SavedBlockData } from "dmeditor";
import { getWidgetBlocks, loadWidgetBlocks } from "./store/widgetBlocks";

function App(props: any) {
  const [isRefresh, setIsRefresh] = useState(true);

  const {
    site: { id: siteID },
  } = useSiteStore();

  return (
    <>
      <Toaster />
      <ErrorBoundary fallbackRender={ErrorHandler}>
        <BrowserRouter basename="/">
          <div className="App">
            <Routes>
              <Route path="/login" index element={<Login />} />
              <Route element={<AuthLayout />}>
                <Route element={<Outlet />}>
                  <Route
                    path="/fullcreate/:contenttype/:id"
                    element={
                      <DMRouteRender
                        render={(params, navigate, location) => (
                          <FullCreate
                            parent={parseInt(params.id)}
                            contentType={params.contenttype}
                          />
                        )}
                      />
                    }
                  />
                  <Route
                    path="/fulledit/:id"
                    element={
                      <DMRouteRender
                        render={(params, navigate, location) => (
                          <FullEdit id={parseInt(params.id)} />
                        )}
                      />
                    }
                  />
                </Route>
                <Route element={<Layout />}>
                  <Route
                    path="/website"
                    index
                    element={
                      <Navigate to={"/website/main/" + siteID} replace />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Navigate to={"/website/main/" + siteID} replace />
                    }
                  />
                  <Route
                    path="/administration"
                    index
                    element={<Navigate to="/administration/setup" replace />}
                  />
                  <Route
                    path="/klubb"
                    element={
                      <Navigate to="/klubb/iframe/medlemsregister" replace />
                    }
                  />

                  <Route path="klubb" element={<KlubbLayout />}>
                    <Route path="iframe/*" element={<ClubMain />} />
                  </Route>

                  <Route path="krets" element={<KretsMain />}></Route>

                  <Route
                    path="administration"
                    element={<AdministrationLayout />}
                  >
                    <Route path="iframe/*" element={<AdministrationMain />} />
                    <Route path="setup" element={<Setup />} />
                    <Route path="forward-email" element={<ForwardEmail />} />
                    <Route path="users" element={<Users />} />
                  </Route>
                  <Route
                    path="website"
                    element={
                      <LayoutWebsite
                        isRefresh={isRefresh}
                        refreshEnd={(isRefreshValue: boolean) => {
                          setIsRefresh(isRefreshValue);
                        }}
                      />
                    }
                  >
                    <Route path="pages" element={<Pages id={siteID} />} />
                    <Route path="site-settings" element={<SiteSettings />} />
                    <Route path="footer" element={<Footer />} />
                    <Route path="main/:id" index element={<MainContent />} />
                    <Route path="view/:id" index element={<View />} />

                    <Route
                      path="annual_report/create/:id"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <CreateAnnualReport id={params.id} />
                          )}
                        />
                      }
                    />
                    <Route
                      path="annual_report/edit/:id"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <EditAnnualReport id={params.id} />
                          )}
                        />
                      }
                    />
                    <Route
                      path="create/:type/:id"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <div
                              className={"create-contenttype-" + params.type}
                            >
                              <Create
                                parent={parseInt(params.id)}
                                contenttype={params.type}
                                afterAction={() => {
                                  if (params.id === siteID) {
                                    setIsRefresh(true);
                                  }
                                  commonAfterAction(
                                    navigate,
                                    [
                                      getFromParam(location.search),
                                      `/main/${params.id}`,
                                    ],
                                    "/website"
                                  );
                                }}
                              />
                            </div>
                          )}
                        />
                      }
                    />
                    <Route
                      path="edit/:id"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <Edit
                              id={parseInt(params.id)}
                              afterAction={() => {
                                setIsRefresh(true);
                                commonAfterAction(
                                  navigate,
                                  [
                                    getFromParam(location.search),
                                    `/main/${params.id}`,
                                  ],
                                  "/website"
                                );
                              }}
                            />
                          )}
                        />
                      }
                    />
                    <Route
                      path="edit/:contenttype/:id"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <Edit
                              id={parseInt(params.id)}
                              contenttype={params.contenttype}
                              afterAction={() => {
                                setIsRefresh(true);
                                commonAfterAction(
                                  navigate,
                                  [
                                    getFromParam(location.search),
                                    `/main/${params.id}`,
                                  ],
                                  "/website"
                                );
                              }}
                            />
                          )}
                        />
                      }
                    />
                    <Route
                      path="image/multiupload/:parent"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <MultiUploadAction
                              parent={params.parent}
                              afterAction={() => {
                                commonAfterAction(
                                  navigate,
                                  [
                                    getFromParam(location.search),
                                    `/main/${params.parent}`,
                                  ],
                                  "/website"
                                );
                              }}
                            />
                          )}
                        />
                      }
                    />
                    <Route
                      path="file/multiupload/:parent"
                      element={
                        <DMRouteRender
                          render={(params, navigate, location) => (
                            <FileMultiUpload
                              parent={params.parent}
                              format="*"
                              afterAction={() => {
                                commonAfterAction(
                                  navigate,
                                  [
                                    getFromParam(location.search),
                                    `/main/${params.parent}`,
                                  ],
                                  "/website"
                                );
                              }}
                            />
                          )}
                        />
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </div>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
}
//with priorized urls, it does redirection. first url which is not empty will be redirected.
const commonAfterAction = (navigate, urls: any[], rootPath: any) => {
  if (urls.length > 0) {
    for (let url of urls) {
      if (url) {
        navigate(`${rootPath}${url}`);
        break;
      }
    }
  } else {
    navigate(`${rootPath}`);
  }
};

const getFromParam = (location: string) => {
  let params = queryString.parse(location);
  return params["from"];
};

const AuthLayout = () => {
  const { user, setUser, setSite, sites, setSites, site } = useSiteStore();

  const { showBoundary } = useErrorBoundary();

  const initCurrent = async () => {
    try {
      const data = await FetchWithAuth(
        process.env.REACT_APP_BRIDGE_API_URL + "/clubadmin/current"
      );
      if (data.error === false) {
        const sites = data.list;
        if (sites.length === 0) {
          showBoundary({ message: "You have no permission to any site" });
          return;
        }

        const chooseSiteID = getSavedSiteID();
        const savedSite = sites.find(
          (item) => item.location.id === chooseSiteID
        );

        let choosenSite = savedSite ? savedSite : sites[0];
        const userClub = choosenSite["_club"];
        const user = data.user;

        setUser(user);

        setRoles(user.roles);

        setSites(sites);

        const siteID = choosenSite.location.id;
        await initSite(sites, siteID);

        setSite({
          id: siteID,
          name: userClub["org_name"],
          host: userClub.host,
          clubID: userClub["org_id"],
          basePath: userClub["basePath"],
        });

        await loadWidgetBlocks();

        setDMEditorCallback({
          canEditControl: (blockData) => {
            return user.roles.includes("bridge-admin");
          },
          getSavedBlocks: (widget: string) => {
            const result: Array<SavedBlockData> = [];
            getWidgetBlocks().forEach((item) => {
              if (item.widget === widget) {
                result.push({
                  name: item.name,
                  image: dmeConfig.general.imagePath(item.image, "thumbnail"),
                  savedData: item.data,
                });
              }
            });
            return result;
          },
        });
      }
    } catch (err) {
      showBoundary(err);
    }
  };

  useEffect(() => {
    //init site
    initCurrent();
  }, []);

  if (!user || sites.length === 0 || !site.id) {
    return <div className="loading"></div>;
  }
  return (
    <div>
      <DMInit
        lang="nor-NO"
        browseAfterList={(props: any) => (
          <>
            {props.contenttype == "image" ? (
              <div
                style={{
                  border: "1px solid #f0f0f0",
                  background: "white",
                  position: "absolute",
                  bottom: 10,
                  padding: 5,
                }}
              >
                <label style={{ marginBottom: 10 }}>
                  Upload new images (hold ctrl/command to select multi):{" "}
                </label>
                <MultiUpload
                  name="multiUpload"
                  service="content"
                  multi={true}
                  format="image/*"
                  value={[]}
                  afterAction={() => props.refresh(null)}
                  parent={props.parent}
                />
              </div>
            ) : (
              ""
            )}
          </>
        )}
        viewSettings={getViewSettings}
        dateTime={{ format: "DD.MM.YYYY HH:mm" }}
      >
        <Outlet />
      </DMInit>
    </div>
  );
};

export default App;
