import { FetchWithAuth } from "digimaker-ui/util";
import { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";

export const AssignUser = (props: {
  site_id: number;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [memberNumber, setMemberNumber] = useState("");
  const [member, setMember] = useState<any>(false);
  const [result, setResult] = useState<any>("");

  const check = () => {
    FetchWithAuth(
      process.env.REACT_APP_BRIDGE_API_URL +
        "/clubadmin/users/get_member?member_number=" +
        memberNumber
    ).then((data: any) => {
      setMember(data.data);
      setResult("");
    });
  };

  const assign = () => {
    FetchWithAuth(
      process.env.REACT_APP_BRIDGE_API_URL +
        "/clubadmin/users/assign?site_id=" +
        props.site_id +
        "&member_number=" +
        memberNumber +
        "&member_name=" +
        member.first_name +
        " " +
        member.last_name
    ).then((data: any) => {
      if (data.error === false) {
        setResult(true);
        props.onSuccess();
      } else {
        setResult("Failed to add: " + data.data);
      }
    });
  };

  return (
    <div>
      <Modal show centered>
        <Modal.Title>Legg til bruker</Modal.Title>
        <Modal.Body>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <label>Member number: </label>
            <input
              type="number"
              className="form-control"
              style={{ width: 150 }}
              onChange={(e) => setMemberNumber(e.target.value)}
            />
            <Button size="sm" variant="secondary" onClick={check}>
              Sjekk medlem
            </Button>
          </div>
          <div>
            {member !== false && (
              <div style={{ padding: 10 }}>
                {member && (
                  <Alert variant="success">
                    <div>Nummer: {member.user_membernumber}</div>
                    <div>
                      Navn: {member.first_name} {member.last_name}
                    </div>
                  </Alert>
                )}
                {!member && <Alert color="warning">Member not found.</Alert>}
              </div>
            )}

            {result !== "" && (
              <Alert color="warning">
                <div>{result}</div>
              </Alert>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!member} onClick={assign}>
            Bekrefte
          </Button>
          <Button variant="text" onClick={() => props.onCancel()}>
            Kansellere
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
