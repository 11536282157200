import { Alert, Button, Container, Table } from "react-bootstrap";
import { fetchWithAuth, FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import useSiteStore from "../../store/useSiteStore";
import { AssignUser } from "./AssignUser";

export const Users = () => {
  useEffect(() => {
    fetchUsers();
  }, []);

  const { site } = useSiteStore();

  const [users, setUsers] = useState<Array<any>>([]);
  const [adding, setAdding] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUsers = () => {
    const siteID = site.id;

    setLoading(true);
    fetchWithAuth(
      process.env.REACT_APP_BRIDGE_API_URL +
        "/clubadmin/users/get_site_users?site_id=" +
        siteID
    ).then((data) => {
      setLoading(false);
      setUsers(data.data);
    });
  };

  const removeUser = (assignmentID) => {
    fetchWithAuth(
      process.env.REACT_APP_BRIDGE_API_URL +
        "/clubadmin/users/remove?site_id=" +
        site.id +
        "&id=" +
        assignmentID
    ).then((data) => {
      if (data.error === false) {
        fetchUsers();
      } else {
        window.alert(data.data);
      }
    });
  };

  return (
    <div>
      <h2>Brukere</h2>
      <div style={{ textAlign: "right" }}>
        <Button
          size="sm"
          onClick={() => {
            setAdding(true);
          }}
        >
          Legg til
        </Button>

        {adding && (
          <AssignUser
            site_id={site.id}
            onSuccess={() => {
              setAdding(false);
              fetchUsers();
            }}
            onCancel={() => setAdding(false)}
          />
        )}
      </div>
      {!loading && (
        <Container style={{ marginTop: 5 }}>
          {users.length > 0 && (
            <Table hover>
              <thead>
                <tr>
                  <th>Medlemsnummer</th>
                  <th>Navn</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((item) => (
                  <tr>
                    <td>{item.user_member_no}</td>
                    <td>{item.user_name}</td>
                    <td>
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => {
                          if (window.confirm("Bekreft for å fjerne?")) {
                            removeUser(item.id);
                          }
                        }}
                      >
                        <i className="fa fa-trash"></i>
                        Fjerne
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {users.length === 0 && (
            <Alert variant="info">Igen bruker funnet.</Alert>
          )}
        </Container>
      )}
    </div>
  );
};
