import { DMEditor, DMEditorRefType } from "dmeditor";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useRef, useState } from "react";
import { dmeditorFields } from "./fieldConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pageSettings, pageSettingsPage } from "./pageSetting";
import {
  getArticleSummaryFromList,
  jsonToHtml,
} from "../../../components/util";
import { t } from "i18next";
import { Modal, Alert, Button } from "react-bootstrap";

export const Create = (props: { parent: number; contentType: string }) => {
  const editorRef = useRef<DMEditorRefType>(null);
  const nav = useNavigate();
  const [searchParams] = useSearchParams();

  const params = useSearchParams();

  let sending = false;

  const [createdID, setCreatedID] = useState(0);

  const save = (data) => {
    if (sending) {
      return;
    }

    const jsonData = data.data;

    const postData = {
      title: data.page.title,
      coverimage: data.page.cover_image || "",
    };
    postData[dmeditorFields[props.contentType]] = jsonData;

    if (props.contentType === "article") {
      const summaryHtml = getArticleSummaryFromList(jsonData);
      postData["summary"] = summaryHtml;
    }

    if (props.contentType === "page" && data.page.hide_in_menu) {
      postData["hide_in_menu"] = 1;
    }

    sending = true;
    FetchWithAuth(`content/create/${props.contentType}/${props.parent}`, {
      method: "POST",
      body: JSON.stringify(postData),
    })
      .then((data) => {
        sending = false;
        if (data.error === false) {
          setShowSavedOption(true);
          setCreatedID(data.data.location.id);
        } else {
          window.alert(data.data);
        }
      })
      .catch((d) => {
        sending = false;
        window.alert(d.message);
        nav(`/`);
      });
  };

  const goBack = () => {
    if (searchParams.has("from")) {
      nav(("/website" + searchParams.get("from")) as string);
    } else {
      nav(`/website/main/${props.parent}`);
    }
  };

  const [showSavedOption, setShowSavedOption] = useState(false);
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  const cancel = () => {
    setShowCancelWarning(true);
  };

  const continueEditing = () => {
    let url = "/fulledit/" + createdID;
    if (searchParams.has("from")) {
      url += "?from=" + searchParams.get("from");
    } else {
      url += "?from=/main/" + props.parent;
    }
    nav(url);
  };

  const fetchData = async () => {
    const current = editorRef.current;
    if (current) {
      current.setPageSettings(
        (props.contentType === "page" ? pageSettingsPage : pageSettings) as any
      );
    }

    const template = params[0].get("template");
    let templateData = [];
    let title = "Ny side";
    if (template) {
      const templateContent = await FetchWithAuth(
        `content/get/template/${template}`
      );
      if (templateContent) {
        templateData = templateContent.data.body;
        title = templateContent.data.title;
      }

      if (current) {
        current.setData(templateData);
        current.setPageData({ title: title, cover_image: "" });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {showSavedOption && (
        <div>
          <Modal show={true} centered>
            <Modal.Title style={{ padding: 10 }}>Lagring vellykket</Modal.Title>
            <Modal.Body>
              <Alert variant="info">
                Lagringen er vellykket, vil du fortsette å redigere eller gå
                tilbake?
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => goBack()}>Gå tilbake</Button>
              <Button variant="light" onClick={continueEditing}>
                Fortsett å redigere
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {showCancelWarning && (
        <div>
          <Modal show={true} centered>
            <Modal.Title style={{ padding: 10 }}>Advarsel</Modal.Title>
            <Modal.Body>
              <Alert variant="warning">
                Er du sikker på at du vil avbryte redigeringen? Det vil ikke
                lagre innholdet ditt.
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => goBack()}>
                Bekrefte
              </Button>
              <Button
                variant="light"
                onClick={() => setShowCancelWarning(false)}
              >
                Fortsett å redigere
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <DMEditor ref={editorRef} onSave={save} onCancel={cancel} />
    </div>
  );
};
